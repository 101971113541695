import * as React from "react"
import { navigate } from 'gatsby-link'

import AnimationWrapper from "../components/animationWrapper"

import "./contactForm.css"

function encode(data) {
  const formData = new FormData()

  for (const key of Object.keys(data)) {
    formData.append(key, data[key])
  }

  return formData
}

const ApplicationForm = ( { position }) => {

  const subject = `Formularz aplikacyjny na stanowisko: ${position}`;

  // recreate full listing name from slug
  if (position){
    position = position.split('-');
    for (var i = 0; i < position.length; i++) {
      position[i] = position[i].charAt(0).toUpperCase() + position[i].slice(1);
    }
    position = position.join(" ");
  }

  const [state, setState] = React.useState({
    ['subject']: subject,
    ['position'] : position,
  })

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleAttachment = (e) => {
    setState({ ...state, [e.target.name]: e.target.files[0] })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }

    return (
      <AnimationWrapper className="application-form">
        <form
          name="application"
          method="POST"
          action="/success"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={handleSubmit}
        >
          <div className="form-container">
            <input type="hidden" name="form-name" value="application" />
            <input type="hidden" name="subject" id="form-field-subject" value={subject} />
            <label htmlFor="form-field-position" className="contact-form-label contact-form-hidden">Stanowisko
              <input type="text" name="position" id="form-field-position" value={position} />
            </label>
            <label className="hidden">
              Don’t fill this out if you’re human: <input name="bot-field" onChange={handleChange} />
            </label>
            <label for="form-field-name" className="contact-form-label">Imię i nazwisko
              <input type="text" name="name" id="form-field-name" className="contact-form-input" title="* Imię i nazwisko" required="required" onChange={handleChange} />
            </label>
            <label for="form-field-email" className="contact-form-label">E-mail
              <input type="email" name="email" id="form-field-email" className="contact-form-input " title="* E-mail" required="required" onChange={handleChange} />
            </label>
            <label for="form-field-tel" className="contact-form-label">Nr tel.
              <input type="tel" name="tel" id="form-field-phone" className="contact-form-input " title="* Nr tel." onChange={handleChange} />
            </label>
            {position === 'przedstawiciel-handlowy' &&
              <label for="form-field-region" className="contact-form-label">Region
                <input type="text" name="region" id="form-field-region" className="contact-form-input " title="Region" onChange={handleChange} />
              </label>
            }
            <label for="form-field-cv" className="contact-form-label">Załącz swoje CV:
              <input type="file" name="attachment" id="form-field-cv" className="contact-form-input" onChange={handleAttachment} />
            </label>
            <button type="submit" className="contact-form-submit-btn btn-color-dark" id="contact-form-submit-btn">Wyślij</button>
          </div>
        </form>
      </AnimationWrapper>
    )
  }

export default ApplicationForm