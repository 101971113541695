import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelopeOpen, faMapMarker } from '@fortawesome/free-solid-svg-icons'
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { GatsbyImage } from "gatsby-plugin-image";

import AnimationWrapper from "../components/animationWrapper"
import "./contactTile.css"

const ContactTile = ( ) => {

  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
        footer: mdx(
          frontmatter: {slug: {eq: "footer"}, type: {eq: "global"}}
        ){
          frontmatter{
            logo{
              childImageSharp{
                gatsbyImageData(
                  webpOptions: {quality: 95}
                )
              }
            }
          }
        }
        contact: mdx(
          frontmatter: {slug: {eq: "contact-global"}, type: {eq: "global"}}
        ){
          frontmatter{
            name
            phone
            phone_postfix
            phone_alt
            email
            address{
              street
              postal_code
              locality
              region
            }
          }
        }
      }
    `
  )


  return (
    <AnimationWrapper className="contact-tile-container" animationType="fadeInRight">
      <div className="contact-tile">
          {data.footer.frontmatter.logo &&
            <GatsbyImage
              image={data.footer.frontmatter.logo.childImageSharp.gatsbyImageData}
              alt={data.site.siteMetadata.title}
            />
          }
        <br />
        {data.contact.frontmatter.name &&
          <div className="section-description-small">{data.contact.frontmatter.name}.</div>
        }
        <ul className="contact-info-wrapper">
          {data.contact.frontmatter.address?.street &&
            <li>
              <FontAwesomeIcon icon={faMapMarker} />
              <AnchorLink
                to="/kontakt#location-map-pin"
                title={`${data.contact.frontmatter.address?.street}, ${data.contact.frontmatter.address?.postal_code} ${data.contact.frontmatter.address?.locality}`} />
            </li>
          }
          {data.contact.frontmatter.email &&
            <li><FontAwesomeIcon icon={faEnvelopeOpen} /><a href={`mailto:${data.contact.frontmatter.email}`}> {data.contact.frontmatter.email}</a></li>
          }
          {data.contact.frontmatter.phone &&
            <li><FontAwesomeIcon icon={faPhone} />
              <div>
                <a href={`tel:${data.contact.frontmatter.phone}`}> {data.contact.frontmatter.phone}</a><br />
                {data.contact.frontmatter.phone_alt &&
                  <a href={`tel:${data.contact.frontmatter.phone_alt}`}> {data.contact.frontmatter.phone_alt}</a>
                }
              </div>
            </li>
          }
        </ul>
      </div>
    </AnimationWrapper>
  )
}

export default ContactTile